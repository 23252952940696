.loaderComponentContainer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 50%);
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    border: 5px solid #fff;
    border-bottom-color: #ff3d00;
    animation: rotation 1s linear infinite;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@import "../../assets/scss/mixin_variables";

.homePageContainer {
  .allLinksWrapper {
    h6 {
      padding-left: 16px;
    }
    ol {
      list-style-type: disclosure-closed;
    }
    .nav-link {
      color: $blue;
      padding: 2px 0;
      font-size: 14px;
      margin-bottom: 3px;
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@import "./assets/scss/mixin_variables.scss";

body {
  margin: 0;
  padding: 0;
  padding-top: 65px;
  overflow-y: scroll;
  &.noTopPadding {
    padding-top: 0;
  }
}

.mainContentContainer {
  min-height: calc(100vh - 157px);
}

$borderWidth: 1.5px;
$bthHeight: 40px;
.btn.cmnBorderBtn {
  width: 100%;
  color: $blue;
  border-radius: 0;
  background-color: $white;
  border: $borderWidth solid $blue;
  min-height: $bthHeight;
  @include transition(all 0.3s);
  &.widthAuto {
    width: auto;
  }
  &:hover,
  &:active {
    color: $white;
    background-color: $blue;
  }
  &.yellow {
    color: $yellow;
    border: $borderWidth solid $yellow;
    &:hover,
    &:active {
      color: $white;
      background-color: $yellow;
      border: $borderWidth solid $yellow;
    }
  }
}

.btn.cmnFillBtn {
  width: 100%;
  color: $white;
  border-radius: 0;
  background-color: $blue;
  border: $borderWidth solid $blue;
  min-height: $bthHeight;
  @include transition(all 0.3s);
  &.widthAuto {
    width: auto;
  }
  &:hover,
  &:active {
    color: $blue;
    border: $borderWidth solid $blue;
    background-color: $white;
  }
  &.danger {
    background-color: $danger;
    border: $borderWidth solid $danger;
    &:hover,
    &:active {
      color: $danger;
      border: $borderWidth solid $danger;
      background-color: $white;
    }
  }
}

.btn.cmnPlainBtn {
  color: $blue;
  width: 100%;
  border-radius: 0;
  font-weight: 500;
  text-decoration: none;
  padding: 6px 0 2px 0;
  border-bottom: $borderWidth solid $white;
  min-height: $bthHeight;
  @include transition(all 0.3s);
  &.widthAuto {
    width: auto;
  }
  &:hover,
  &:active {
    color: $blue;
    border-bottom: $borderWidth solid $blue;
  }
}

input.form-control,
select.form-select {
  border-radius: 0;
  border-width: $borderWidth;
  &:focus,
  &:hover {
    box-shadow: none;
    border-color: $blue;
  }
}

input[type="radio"].form-check-input:checked {
  background-color: $b1;
  border-color: $b1;
}

input[type="radio"].form-check-input:focus {
  border-color: $b1;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(38 38 38 / 25%);
}

.cmnCheckboxWrapper {
  .checkboxLabel {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
    overflow: hidden;
    transition: all 0.2s ease;
    display: flex;
    width: 100%;
    font-size: 14px;
    &:not(:last-child) {
      margin-right: 6px;
    }
    &:hover {
      background: rgba(0, 119, 255, 0.06);
      span:first-child {
        border-color: $b1;
      }
    }
    span {
      float: left;
      white-space: nowrap;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
      &:first-child {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        transform: scale(1);
        border: 1px solid #cccfdb;
        transition: all 0.2s ease;
        box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
      }
      &:first-child svg {
        position: absolute;
        top: 3px;
        left: 2px;
        fill: none;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }
      &:last-child {
        padding-left: 8px;
        line-height: 18px;
      }
    }
  }
  .checkboxInput {
    position: absolute;
    visibility: hidden;
    &:checked + .checkboxLabel span:first-child {
      background: $b1;
      border-color: $b1;
      animation: wave-4 0.4s ease;
    }
    &:checked + .checkboxLabel span:first-child svg {
      stroke-dashoffset: 0;
    }
  }

  .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }
  @media screen and (max-width: 640px) {
    .checkboxLabel {
      width: 100%;
      display: inline-block;
    }
  }
  @-moz-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @-webkit-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @-o-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
}

.vSpace {
  margin: 20px 0;
}

.MuiFormControl-root p.Mui-error {
  margin-left: 0;
  margin-right: 0;
}

.cmnModalWrapper {
  .MuiDialogActions-root {
    padding: 20px 24px;
  }
  .infoMsg {
    color: #ffc107;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

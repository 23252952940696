@import "../../assets/scss/mixin_variables";

.headerComponentContainer {
  .logoWrap {
    cursor: pointer;
    .mainLogo {
      width: 45px;
      height: 45px;
    }
  }
  .switchKeyWrapper {
    background-color: #fff;
    border-radius: 8px;
    padding: 5px 15px;
    color: #000;
    transform: scale(0.7);
    p {
      font-size: 10px;
    }
  }
}
.drawerMenuWrapper {
  .logoWrap {
    background-color: #000;
    .mainLogo {
      width: 48px;
      height: 48px;
    }
  }
  .sideNavLink {
    text-decoration: none;
    font-weight: 500;
    color: #000;
    font-size: 14px;
    &.logout {
      color: #d32f2f;
    }
  }
  .sideNavAccordionWrap {
    box-shadow: none;
    .MuiAccordionSummary-root {
      min-height: 37px;
    }
    .MuiAccordionSummary-content,
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }
    p {
      text-decoration: none;
      font-weight: 500;
      color: #000;
      font-size: 14px;
    }
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -o-transition: $args;
  transition: $args;
}

$white: #ffffff;
$b1: #262626;
$blue: #4cbcec;
$yellow: #ffc107;
$danger: #dc3545;

@import "../../assets/scss/mixin_variables";

.priceSupportRequestPageContainer {
  .heading {
    text-align: center;
    color: $blue;
    font-weight: 600;
    font-size: 30px;
    margin: 14px 0 30px 0;
  }
  .requestInfoWrapper {
    margin-bottom: 30px;
  }
  .distributorInfoWrap {
    border: 1px solid rgba(0, 0, 0, 0.26);
    padding: 10px;
    margin: 5px 0;
    border-radius: 4px;
  }
  .actionButtonsWrapper {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    position: fixed;
    background-color: #fffcfc;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .actionBtnWrap {
    text-align: center;
    padding: 15px 0;
    .saveBtn,
    .cancelBtn {
      margin: 0 10px;
      min-width: 150px;
    }
  }
  .datePickerWrap {
    .MuiSvgIcon-root {
      font-size: 22px;
    }
    .clearButton {
      opacity: 1 !important;
    }
  }
}

@import "../../assets/scss/mixin_variables";

.footerComponentContainer {
  padding: 10px 0;
  margin-top: 30px;
  background: #f8f8f8;
  .confidentialTextWrapper {
    .txt {
      margin: 0;
      color: #ff7f41;
      font-size: 25px;
      display: inline-block;
      text-transform: uppercase;
      border-top: 2px solid #ff7f41;
      border-bottom: 2px solid #ff7f41;
    }
  }
  .privacyTextWrapper {
    .link {
      color: $blue;
      font-weight: 500;
    }
  }
}

@import "../../assets/scss/mixin_variables";

.DataTableComponentContainer {
  // This is for all filter Input fields
  .MuiTableCell-head {
    .MuiInputBase-root {
      font-size: 12px;
    }
  }
  .MuiTableCell-head .Mui-TableHeadCell-Content-Wrapper {
    white-space: nowrap;
  }
}

// This is for Dropdown
.dataTableSelectOptWrapper {
  .MuiInputBase-input {
    padding: 5px;
  }
}

.pageTitleComponentContainer {
  .headingWrapper {
    margin: 15px 0 10px;
    display: flex;
    justify-content: space-between;
    .heading {
      margin: 0;
      font-size: 20px;
    }
  }
}
